import React from 'react';
import { useStyles2, Link } from '@grafana/ui';
import _ from 'lodash';
import Plotly from 'react-plotly.js';
import { DataViewProps } from '../../types';
import { config, getLayout, getStyles } from '../../config';
import { cx } from '@emotion/css';
import { EventAnnotations } from '../EventAnnotations/EventAnnotations';

export const DisplayFields: React.FC<DataViewProps> = (props: DataViewProps) => {
  const { time, data, columns, units, totals } = props;
  const timeValues = time?.values.toArray();
  const timeStrings = timeValues?.map((item: number) => time?.display?.(item)?.text ?? item);
  const vesselId = _.head(data)?.labels?.id;
  const styles = useStyles2(getStyles());
  const layout = useStyles2(getLayout());
  const markerColor = 'rgb(235, 123, 24)';

  return (
    <div className={cx(styles.list)}>
      {_.flatten(
        columns.map((column) => {
          const unit = units.find((u: any) => u.name === `${column.unit}_symbol`)?.value;
          const totalUnit = units.find((u: any) => u.name === `${column.totalUnit}_symbol`)?.value;
          const totalField = totals?.fields.find(
            (field) => field.name === column.totalKey && field.labels?.vessel_id === vesselId
          );
          const totalValue = totalField?.values?.toArray()[0];

          const field = data.find((f) => f?.labels?.metric === column.key);
          const values = field?.values?.toArray() || [];
          const mean: number = _.chain(values).mean().round(2).value();
          const max = _.maxBy(_.zip(values, timeStrings ?? []), 0);
          const min = _.minBy(_.zip(values, timeStrings ?? []), 0);
          const max_y = max?.[0] ?? null;
          const max_time = max?.[1] ?? _.last(timeStrings) ?? 0;

          const min_y = min?.[0] ?? null;
          const min_time = min?.[1] ?? _.first(timeStrings) ?? 0;

          return (
            <>
              <Link href={`/d/vessel?var-vessels=${vesselId}`} className={cx(styles.column)}>
                {column.title}
              </Link>
              <Plotly
                data={[
                  {
                    x: timeStrings,
                    y: values,
                    type: 'scatter',
                    mode: 'lines',
                    hovertemplate: `%{x}: %{y} ${unit}`,
                    name: column.title,
                    line: {
                      width: 3,
                    },
                  },
                  {
                    y: [max_y],
                    x: [max_time],
                    mode: 'markers',
                    name: `Maximum ${column.title}`,
                    hovertemplate: `%{y} ${unit}`,
                    marker: {
                      color: markerColor,
                    },
                  },
                  {
                    y: [min_y],
                    x: [min_time],
                    mode: 'markers',
                    name: `Minimum ${column.title}`,
                    hovertemplate: `%{y} ${unit}`,
                    marker: {
                      color: markerColor,
                    },
                  },
                ]}
                layout={{
                  ...layout,
                  xaxis: {
                    range: [_.first(timeStrings), _.last(timeStrings)],
                    autorange: false,
                    showgrid: false,
                    showticklabels: false,
                  },
                  shapes: mean
                    ? [
                        {
                          type: 'line',
                          layer: 'below',
                          xref: 'paper',
                          yref: 'y',
                          x0: 0,
                          y0: mean,
                          x1: 1,
                          y1: mean,
                          line: {
                            color: markerColor,
                            width: 2,
                            dash: 'dot',
                          },
                        },
                      ]
                    : [],
                  annotations: mean
                    ? [
                        {
                          xref: 'paper',
                          yref: 'y',
                          x: 0.5,
                          y: mean,
                          text: `${mean} ${unit}`,
                          ax: 0,
                          ay: -8,
                          showarrow: true,
                          font: {
                            color: markerColor,
                            size: 10,
                          },
                        },
                      ]
                    : [],
                }}
                config={config}
              />
              <span className={cx(styles.total)}>
                {totalValue ? _.round(totalValue) : '--'} {totalUnit}
              </span>
            </>
          );
        })
      )}
      <EventAnnotations {...props} />
    </div>
  );
};
