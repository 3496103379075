import React from 'react';
import { VerticalGroup, useStyles2 } from '@grafana/ui';
import _ from 'lodash';
import { RowProps, DataViewProps } from '../../types';
import { getStyles } from '../../config';
import { cx } from '@emotion/css';
import { DisplayFields } from '../DisplayFields/DisplayFields';

const DataView: React.FC<DataViewProps> = (props) => (
  <div className={cx(useStyles2(getStyles()).row)}>
    <h6 className={cx(useStyles2(getStyles()).name)}>
      <a href={`/d/vessel?var-vessels=${_.head(props.data)?.labels?.id}`}>{props.name}</a>
    </h6>
    <DisplayFields {...props} />
  </div>
);

export const Row: React.FC<RowProps> = ({ grouped, columns }) => (
  <VerticalGroup spacing="none">
    {_.map(grouped.group, (fields, key) => (
      <DataView
        data={fields}
        time={grouped.time}
        name={`${key}`}
        columns={columns}
        units={grouped.units}
        totals={grouped.totals}
        annotations={grouped.annotations ?? []}
      />
    ))}
  </VerticalGroup>
);
