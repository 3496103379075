import React from 'react';
import { GrafanaTheme2, PanelData, PanelProps } from '@grafana/data';
import { Options, VesselGroups, Column } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, CollapsableSection, Link } from '@grafana/ui';
import _ from 'lodash';
import { Row } from '../StatRow/Row';

interface Props extends PanelProps<Options> {}

function reshape(data: PanelData): VesselGroups {
  const time = data.series[0].fields.find((f) => f.name.trim().toLowerCase() === 'time') ?? data.series[0].fields[0];
  const group = _.groupBy(data.series[0].fields.slice(1), (field) => field?.labels?.vessel);
  const totalSeries = data.series.find((s) => s?.refId?.trim().toLowerCase() === 'totals');
  const unitSerie = data.series.find((s) => s.refId?.trim().toLowerCase() === 'units');
  const units = unitSerie?.fields.map((f: any) => {
    return {
      name: f.name,
      value: f.values.toArray()[0]
    };
  });

  return { group, time, annotations: data.annotations, units: units, totals: totalSeries };
}

function getMissingVessels(data: PanelData) {
  const series = data?.series.find((s) => s?.refId?.trim().toLowerCase() === 'missing');
  const names = series?.fields.find((f) => f.name.trim().toLowerCase() === 'name')?.values.toArray();
  const ids = series?.fields.find((f) => f.name.trim().toLowerCase() === 'id')?.values.toArray();
  return _.zip(names, ids);
}

export const StatGrid: React.FC<Props> = ({ data }) => {
  const styles = useStyles2(getStyles());
  const columns: Column[] = [
    {
      key: 'SpeedOverGround',
      totalKey: 'distance_sailed',
      title: 'Speed',
      unit: 'speed_unit',
      totalUnit: 'distance_unit',
    },
    {
      key: 'Consumption',
      totalKey: 'total_consumption',
      title: 'Consumption',
      unit: 'consumption_unit',
      totalUnit: 'total_consumption_unit',
    },
  ];
  const missingVessels = getMissingVessels(data);

  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.data)}>
        <Row grouped={reshape(data)} columns={columns} />
      </div>
      <CollapsableSection label="Vessels without data" isOpen={!!missingVessels.length}>
        <ul className={cx(useStyles2(getStyles()).list)}>
          {missingVessels.map((row) => (
            <li key={row[1]}>
              <Link href={`/d/vessel?var-vessels=${row[1]}`}>{row[0]}</Link>
            </li>
          ))}
        </ul>
      </CollapsableSection>
    </div>
  );
};

const getStyles = () => (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      position: relative;
      overflow: hidden auto;
      height: 100%;
      padding: ${theme.spacing(1)};
    `,
    data: css`
      padding-bottom: ${theme.spacing(2)};
      font-family: ${theme.typography.fontFamilyMonospace};
    `,
    list: css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      list-style-type: none;
      padding: ${theme.spacing(2)};
    `,
  };
};
