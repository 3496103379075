import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export const colours = [
  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf', // blue-teal
];

export const getLayout = () => (theme: GrafanaTheme2) => ({
  width: 500,
  height: 50,
  margin: { l: 2, r: 1, b: 3, t: 3, pad: 1 },
  paper_bgcolor: theme.colors.background.primary,
  plot_bgcolor: theme.colors.background.secondary,
  showlegend: false,

  yaxis: {
    autorange: true,
    hoverformat: '.1f',
    showgrid: false,
    showticklabels: false,
    zeroline: false,
  },
});

export const config = {
  displayModeBar: false,
};

export const getStyles = () => (theme: GrafanaTheme2) => ({
  name: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 2px solid ${theme.colors.text.disabled};
  `,
  column: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    font-size: ${theme.typography.h6.fontSize};
  `,
  total: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    font-size: ${theme.typography.h6.fontSize};
    font-family: ${theme.typography.fontFamilyMonospace};
    padding-right: ${theme.spacing(1)};
  `,
  row: css`
    padding-bottom: ${theme.spacing(2)};
  `,
  list: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${theme.spacing(1)};
    list-style-type: none;
    align-items: center;
    justify-items: stretch;
  `,
});
