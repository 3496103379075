import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { Field } from '@grafana/data';
import _ from 'lodash';
import Plotly from 'react-plotly.js';
import { DataViewProps, AnnotationsGroup, SourceObject } from '../../types';
import { colours, config, getLayout, getStyles } from '../../config';
import { cx } from '@emotion/css';

const annotationText = (vesselId: string, tags: string[]) => {
  const eventType = _.first(tags);
  const eventId = _.last(tags);
  const text = tags.slice(1, -1).join(', ');
  return `<b data-type='${eventType}' data-id='${vesselId}' data-event='${eventId}'>${_.startCase(
    eventType?.replace('Event', '').replace('Measured', '')
  )}
    ${!!text ? '<br />' + text : ''} </b>`;
};

const makeSource = (groups: AnnotationsGroup[], time: Field): any[] => {
  const source = _.map(groups, (g, key) => ({
    x: g.group.map((va: SourceObject) => time.display?.(va.time)?.text),
    y: g.group.map(() => g.colIndex),
    text: g.group.map((va: SourceObject) => annotationText(va.text, va.tags)),
    type: 'scatter',
    mode: 'markers',
    hoverinfo: 'text',
    name: key,
    line: {
      color: colours[g.colIndex],
    },
    marker: {
      symbol: Array(g.group.length).fill(42),
      size: 6,
      line: {
        color: colours[g.colIndex],
        width: 3,
      },
    },
  }));
  return source || [];
};

const annotationData = (groups: AnnotationsGroup[], time?: Field, first?: string, last?: string): any[] =>
  _.concat(
    [
      {
        x: [first, last],
        y: [0, _.maxBy(groups, 'colIndex')?.colIndex],
        type: 'scatter',
        mode: 'lines',
        name: 'Events',
        line: {
          color: '#rgba(55,55,55,0.0)',
        },
      },
    ],
    time ? makeSource(groups, time) : []
  );

export const EventAnnotations: React.FC<DataViewProps> = ({ time, data, annotations }) => {
  const vesselId = _.head(data)?.labels?.id;
  const timeValues = time.values.toArray();
  const sourceAnnotations = _.flatten(annotations?.map((a) => a.source));
  const styles = useStyles2(getStyles());
  const layout = useStyles2(getLayout());
  let colIndex = -1;
  const first: any = time?.display?.(_.first(timeValues))?.text ?? _.first(timeValues);
  const last: any = time?.display?.(_.last(timeValues))?.text ?? _.last(timeValues);
  const groupedVesselAnnotations: AnnotationsGroup[] = _.map(
    _.groupBy(sourceAnnotations, (a) => _.first(a?.tags)?.replace('ArrivedAt', '')?.replace('Left', '')),
    (g) => {
      colIndex = (colIndex + 1) % colours.length;
      const group = g.filter((a) => a?.text === vesselId);
      const fg: AnnotationsGroup = {
        group,
        colIndex,
      };
      return fg;
    }
  );
  return (
    <>
      <span className={cx(styles.column)}>Events</span>
      <Plotly
        onClick={(e: any) => {
          const points: SourceObject[] = e.points;
          const domParser = new DOMParser();
          const html = domParser.parseFromString(points?.[0]?.text, 'text/html');
          const lable = html?.querySelectorAll('b')?.[0];
          const pointData = lable?.dataset;
          if (pointData?.type === 'BunkerMeasuredEvent') {
            window.location.href = `/d/bunker/measured-bunker?var-eventid=${pointData?.event}&var-vessel=${pointData?.id}`;
          }
        }}
        data={annotationData(groupedVesselAnnotations, time, first, last)}
        layout={{
          ...layout,
          xaxis: {
            range: [first, last],
            autorange: false,
            showgrid: false,
            showticklabels: false,
          },
          height: 40,
        }}
        config={config}
      />
      <span className={cx(styles.total)}>
        {_.reduce(groupedVesselAnnotations, (total, g) => total + g.group.length, 0) || '-'}
      </span>
    </>
  );
};
